import React, { useState, useEffect, useCallback } from 'react';
import frame1 from '../asset/photoframes-1.jpg';
import frame2 from '../asset/photoframes-2.jpg';
import frame3 from '../asset/photoframes-3.jpg';
import frame4 from '../asset/photoframes-4.jpg';
import frame5 from '../asset/photoframes-5.jpg';
import frame6 from '../asset/photoframes-6.jpg';
import frame7 from '../asset/photoframes-7.jpg';
import photobutton from '../asset/photoframes-dp1.jpg';
import acrlicproductbutton from '../asset/photomug-dp.jpg';
import Caricaturesbutton from '../asset/avatar-dp.jpg';
import woodbutton from '../asset/woodengraving-dp.jpg';
import neonbutton from '../asset/neon-dp.jpg';
import acrylicbutton from '../asset/photoprint-dp.jpg';
import acrylickeychain from '../asset/acrlickeychain-dp.jpg';
import '../styles/PhotoButtonPage.css';

const PhotoButtonPage = () => {
  const images = [frame1, frame2, frame3, frame4, frame5, frame6, frame7];
  const buttons = [
    { src: photobutton, route: '1', name: 'Photo Frames' },
    { src: acrlicproductbutton, route: '2', name: 'Acrylic Products' },
    { src: Caricaturesbutton, route: '3', name: 'Caricatures' },
    { src: woodbutton, route: '4', name: 'Wood Engravings' },
    { src: neonbutton, route: '5', name: 'Neon Signs' },
    { src: acrylicbutton, route: '6', name: 'Acrylic Prints' },
    { src: acrylickeychain, route: '7', name: 'Acrylic Keychains' },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  }, [images.length]);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  }, [images.length]);

  useEffect(() => {
    const interval = setInterval(nextSlide, 4000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartX(e.touches[0].clientX); // Capture the starting touch position
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;

    const diff = e.touches[0].clientX - startX; // Calculate the difference in touch position
    if (diff > 50) {
      prevSlide();
      setIsDragging(false); // Prevent multiple rapid swipes
    } else if (diff < -50) {
      nextSlide();
      setIsDragging(false);
    }
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleSectionScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="photo-button-page">
      <div
        className="carousel"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div className="carousel-inner">
          <img
            src={images[currentIndex]}
            alt={`Slide ${currentIndex}`}
            className="carousel-image"
          />
        </div>
        <button className="prev" onClick={prevSlide}>
          &#10094;
        </button>
        <button className="next" onClick={nextSlide}>
          &#10095;
        </button>
        <div className="dots">
          {images.map((_, index) => (
            <span
              key={index}
              className={`dot ${index === currentIndex ? 'active' : ''}`}
              onClick={() => setCurrentIndex(index)}
            ></span>
          ))}
        </div>
      </div>

      <div className="img-frames">
        <h1>--YOURS FAVOURITE PICKS--</h1>
      </div>

      <div className="image-buttons">
        <div className="button-row btn-r-res">
          {buttons.slice(0, 2).map((button, index) => (
            <div key={index} className="button-container">
              <img
                src={button.src}
                alt={button.name}
                title={button.name}
                className="image-button rectangle"
                onClick={() => handleSectionScroll(button.route)}
              />
              <p className="button-name">{button.name}</p>
            </div>
          ))}
        </div>
        <div className="button-row btn-r-res1">
          {buttons.slice(2, 5).map((button, index) => (
            <div key={index} className="button-container">
              <img
                src={button.src}
                alt={button.name}
                title={button.name}
                className="image-button rectangle"
                onClick={() => handleSectionScroll(button.route)}
              />
              <p className="button-name">{button.name}</p>
            </div>
          ))}
        </div>
        <div className="button-row btn-r-res1">
          {buttons.slice(5).map((button, index) => (
            <div key={index} className="button-container">
              <img
                src={button.src}
                alt={button.name}
                title={button.name}
                className="image-button rectangle"
                onClick={() => handleSectionScroll(button.route)}
              />
              <p className="button-name">{button.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PhotoButtonPage;
