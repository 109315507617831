import React, { useState, useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/AcrylicProductPage.css';

const AcrylicProductPage = () => {
  const [cartItems, setCartItems] = useState(() => {
    // Retrieve initial cart items from local storage
    const storedItems = localStorage.getItem('cartItems');
    return storedItems ? JSON.parse(storedItems) : [];
  });

  // Save cart items to local storage whenever cartItems state changes
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const acrylicProducts = [
    {
      code: 'IS-20',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-20.jpg?alt=media&token=de52d1eb-2b31-4003-82de-87d62c45e8d4',
      name: 'Normal Mug',
      price: 350,
    },
    {
      code: 'IS-21',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-21.png?alt=media&token=1bfd40d7-eb9a-4e22-9c0c-ebba0a0c7826',
      name: 'Magic Cup',
      price: 350,
    },
    {
      code: 'IS-22',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-22.jpg?alt=media&token=876ef280-6a64-439a-a2b1-38a820844cd9',
      name: 'Patch Mug',
      price: 399,
    },
    {
      code: 'IS-23',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-23.jpg?alt=media&token=e02843d4-f7ae-40c1-9183-a74ac5abfbc7',
      name: 'Fur Pillow',
      price: 450,
    },
    {
      code: 'IS-24',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-24.jpg?alt=media&token=589e856d-1a72-46a2-8695-b09f8c1e1245',
      name: 'Heart Shape Pillow',
      price: 480,
    },
    {
      code: 'IS-25',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-25.jpg?alt=media&token=ada04857-daa5-4b56-ac19-6ee6f98e902b',
      name: 'Magic Pillow',
      price: 599,
    },
    {
      code: 'IS-26',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-26.jpg?alt=media&token=3de9da79-5898-4ee1-85b9-637013ccb155',
      name: 'Photo Pillow',
      price: 399,
    },
    {
      code: 'IS-27',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-27.jpg?alt=media&token=85971cb9-a543-4c0f-90b1-bf9e9ba64c41',
      name: 'Photo Sipper',
      price: 450,
    },
    {
      code: 'IS-28',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-28.jpg?alt=media&token=67c3a834-760a-4fa9-bae1-e270b1b9caad',
      name: 'Photo Rock',
      price: 599,
    },
    {
      code: 'IS-29',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-29.jpg?alt=media&token=7973252a-aa68-436d-8220-6e9c19ee4534',
      name: 'Sublimation Glass',
      price: 549,
    },
    {
      code: 'IS-30',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-30.jpg?alt=media&token=49ae83e4-c81d-4900-a25e-01eb5ca79089',
      name: 'T Shirt Printing',
      price: 399,
    },
    {
      code: 'IS-31',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-31.jpg?alt=media&token=9d456ef6-924d-4132-8794-ae23ef55b038',
      name: 'Single Side Key Chain',
      price: 69,
    },
    {
      code: 'IS-32',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-32.jpg?alt=media&token=12356608-d4a7-4557-8382-f3565bfaeaaf',
      name: 'Double Side Key Chain',
      price: 99,
    },
    {
      code: 'IS-33',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-33.jpg?alt=media&token=a71879aa-3a8b-4384-91de-354980125ee4',
      name: 'LED Pillow',
      price: 699,
    },
    // other products...
  ];

  const addToCart = (product) => {
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find(item => item.code === product.code);

      if (existingProduct) {
        return prevItems.map(item =>
          item.code === product.code
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });

    toast.success(`${product.name} added to cart!`, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  return (
    <div className="acrylic-product-gallery" id="2">
      <h1>ACRYLIC PRODUCT</h1>
      <div className="acrylic-product-container">
        {acrylicProducts.map((product, index) => (
          <div key={index} className="acrylic-product-card">
            <img src={product.img_url} alt={product.name} className="acrylic-products-image" />
            <h3>{product.name}</h3>
            <p>₹{product.price}</p>
            <button className="add-to-cart-btn acr" onClick={() => addToCart(product)}>
              <FaShoppingCart /> Add to Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcrylicProductPage;
