import React, { useState, useEffect, useCallback } from 'react';
import { auth, db } from '../firebase'; // Import Firebase services
import { collection, getDocs, query, where, doc, getDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { FaClock, FaCheckCircle, FaHome } from 'react-icons/fa'; // Import React Icons
import '../styles/MyOrderListPage.css'; // Your custom CSS

const MyOrderListPage = () => {
  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null); // State to hold selected order details
  const [isOrderDetailsVisible, setIsOrderDetailsVisible] = useState(false); // Toggle visibility of order details
  const navigate = useNavigate();
  const { orderId } = useParams(); // Get orderId from the URL if available

  // Direct URL to the empty order image
  const emptyOrderImage = 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2Fno_order.png?alt=media&token=b91a308c-2bbf-4588-9088-0f1d29dd9ec5'; // Use your actual image URL

  // Fetch user orders from Firestore
  const fetchUserOrders = useCallback(async (userId) => {
    const ordersRef = collection(db, "orders");
    const q = query(ordersRef, where("user_id", "==", userId)); // Filter by user ID
    const querySnapshot = await getDocs(q);
    const userOrders = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    // Sort orders by created_at (assuming it's a timestamp)
    userOrders.sort((a, b) => b.created_at - a.created_at);
    setOrders(userOrders);

    // If there's an orderId in the URL, fetch its details
    if (orderId) {
      fetchOrderDetails(orderId);
    }
  }, [orderId]); // Include orderId as a dependency

  // Fetch order details
  const fetchOrderDetails = async (orderId) => {
    const orderRef = doc(db, "orders", orderId);
    const orderSnapshot = await getDoc(orderRef);

    if (orderSnapshot.exists()) {
      setOrderDetails(orderSnapshot.data());
      setIsOrderDetailsVisible(true); // Show order details
    } else {
      console.log("No such order!");
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        fetchUserOrders(currentUser.uid); // Fetch orders for the logged-in user
      } else {
        navigate('/login'); // Redirect to login if not logged in
      }
    });

    return () => unsubscribe();
  }, [fetchUserOrders, navigate]); // Add fetchUserOrders to the dependency array

  // Navigate to the order details page
  const handleViewOrder = (orderId) => {
    navigate(`/orders/${orderId}`); // Adjust the route to include orderId in URL
    fetchOrderDetails(orderId); // Fetch order details when viewing
  };

  // Close order details
  const closeOrderDetails = () => {
    setIsOrderDetailsVisible(false);
    setOrderDetails(null); // Clear order details
  };

  // Navigate back to the previous page
  const handleHomeClick = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <div className="my-order-list-container">
      <header className="header">
        <FaHome className="home-icon" onClick={handleHomeClick} />
        <h1>MY ORDERS</h1>
      </header>
      {orders.length === 0 ? (
        <div className="empty-orders">
          <img src={emptyOrderImage} alt="No Orders" className="empty-image" />
          <p>No orders found. Start shopping now!</p>
        </div>
      ) : (
        <ul className="order-list">
          {orders.map(order => (
            <li key={order.id} className="order-item">
              <div className="order-info">
                <h2>Order ID: {order.id}</h2>
                <div className={`order-status ${order.Confirm_order ? 'confirmed' : 'pending'}`}>
                  <p>Status: {order.Confirm_order ? 'Confirmed' : 'Pending'}</p>
                </div>
              </div>
              <div className="order-icons">
                {order.Confirm_order ? (
                  <FaCheckCircle className="icon confirmed-icon" />
                ) : (
                  <FaClock className="icon pending-icon" />
                )}
                <button className="view-order-button" onClick={() => handleViewOrder(order.id)}>View Order</button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {/* Order Details Section */}
      {isOrderDetailsVisible && orderDetails && (
        <div className="order-details">
          <h2>Order Details for Order ID: {orderId}</h2>
          <button onClick={closeOrderDetails}>Close</button>
          <h3>Status: {orderDetails.Confirm_order ? 'Confirmed' : 'Pending'}</h3>
          <h3>Items:</h3>
          <ul>
            {orderDetails.items.map((item, index) => (
              <li key={index}>
                <p>Product Code: {item.code}</p>
                <p>Size: {item.size}</p>
                <p>Quantity: {item.quantity}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MyOrderListPage;