import React, { useState, useEffect } from 'react';
import { auth, db, storage } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';
import '../styles/OrderPage.css';

const OrderPage = () => {
  const [user, setUser] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [images, setImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const { orderId } = useParams();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchUserData(currentUser.uid);
        fetchOrderDetails(orderId);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate, orderId]);

  const fetchUserData = async (userId) => {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);
    
    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      setUsername(userData.username);
      setPhone(userData.phone);
      setEmail(userData.email);
      setAddress(userData.address);
    } else {
      console.log("No such user data!");
    }
  };

  const fetchOrderDetails = async (orderId) => {
    const orderRef = doc(db, "orders", orderId);
    const orderSnapshot = await getDoc(orderRef);

    if (orderSnapshot.exists()) {
      const orderData = orderSnapshot.data();
      setOrderDetails({ id: orderSnapshot.id, ...orderData });

      const initialImages = orderData.items.map((item, itemIndex) =>
        Array(item.quantity)
          .fill(null)
          .map((_, quantityIndex) => orderData.imageUrls?.[itemIndex]?.[quantityIndex] || null)
      );
      setImages(initialImages);
    } else {
      console.log("No order found for this order ID.");
    }
  };

  const handleImageSelection = (e, itemIndex, quantityIndex) => {
    const file = e.target.files[0];
    if (file) {
      const newImages = [...images];
      newImages[itemIndex][quantityIndex] = file;
      setImages(newImages);
    }
  };

  const handleRemoveImage = (itemIndex, quantityIndex) => {
    const newImages = [...images];
    newImages[itemIndex][quantityIndex] = null;
    setImages(newImages);
  };

  const sendEmail = async (imageLinks) => {
    try {
      const templateParams = {
        to_name: username,
        from_name: 'Order Confirmation System',
        message: `
          User Details:
          - Name: ${username}
          - Phone: ${phone}
          - Email: ${email}
          - Address: ${address}

          Order Summary:
          ${orderDetails.items
            .map((item, index) => `
              • ${item.code} - Size: ${item.size}, Quantity: ${item.quantity}
              Images: ${imageLinks[index].join(', ')}
            `)
            .join('\n')}
        `,
      };

      await emailjs.send('service_6t6e3qa', 'template_n69iylc', templateParams, 'hV1d93-YHoqqgN4ly');
      toast.success("Order confirmation email sent!", { position: 'top-right' });
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("Error sending email: " + error.message, { position: 'top-right' });
    }
  };

  const handleSubmit = async () => {
    if (!orderDetails.id) return;

    try {
      toast.info("Confirming order, please wait...", { position: 'top-right' });

      const uploadedImageUrls = await Promise.all(
        images.map(async (productImages, productIndex) => {
          const urls = await Promise.all(
            productImages.map(async (image, quantityIndex) => {
              if (image && typeof image !== 'string') {
                const storageRef = ref(storage, `order_images/${user.uid}/${image.name}`);
                await uploadBytes(storageRef, image);
                const url = await getDownloadURL(storageRef);
                return url;
              } else if (typeof image === 'string') {
                return image;
              } else {
                return '';
              }
            })
          );
          return urls;
        })
      );

      const orderRef = doc(db, "orders", orderDetails.id);
      await updateDoc(orderRef, {
        username,
        phone,
        email,
        address,
        imageUrls: uploadedImageUrls.flat(),
        Confirm_order: true
      });

      await sendEmail(uploadedImageUrls);

      toast.success("Order confirmed successfully!", { position: 'top-right' });
      navigate('/orders');
    } catch (error) {
      console.error("Error confirming order:", error);
      toast.error("Error confirming order: " + error.message, { position: 'top-right' });
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleConfirm = () => {
    handleSubmit();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="order-page-container">
      <ToastContainer />

      <h1>CONFIRM YOUR ORDER</h1>

      {orderDetails ? (
        <form className="order-form" onSubmit={(e) => { e.preventDefault(); openModal(); }}>
          <div className="form-group">
            <label>Username:</label>
            <input 
              type="text" 
              value={username} 
              onChange={(e) => setUsername(e.target.value)} 
              required 
              readOnly 
            />
          </div>

          <div className="form-group">
            <label>Phone:</label>
            <input 
              type="tel" 
              value={phone} 
              onChange={(e) => setPhone(e.target.value)} 
              required 
            />
          </div>

          <div className="form-group">
            <label>Email:</label>
            <input 
              type="email" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
              readOnly 
            />
          </div>

          <div className="form-group">
            <label>Address:</label>
            <input 
              type="text" 
              value={address} 
              onChange={(e) => setAddress(e.target.value)} 
              required 
            />
          </div>

          <h2>Upload Images for Your Products</h2>
          {orderDetails.items?.map((item, itemIndex) => (
            <div key={itemIndex} className="form-group">
              <label>{`Upload Images for ${item.code} (Quantity: ${item.quantity})`}</label>
              {[...Array(item.quantity)].map((_, quantityIndex) => (
                <div key={quantityIndex} className="image-upload-container">
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={(e) => handleImageSelection(e, itemIndex, quantityIndex)} 
                  />
                  {images[itemIndex] && images[itemIndex][quantityIndex] && (
                    <div className="image-preview">
                      {typeof images[itemIndex][quantityIndex] === 'string' ? (
                        <img 
                          src={images[itemIndex][quantityIndex]} 
                          alt={`Preview ${item.code} - Quantity ${quantityIndex + 1}`} 
                        />
                      ) : (
                        <img 
                          src={URL.createObjectURL(images[itemIndex][quantityIndex])} 
                          alt={`Preview ${item.code} - Quantity ${quantityIndex + 1}`} 
                        />
                      )}
                      <button 
                        type="button" 
                        className="remove-image-btn" 
                        onClick={() => handleRemoveImage(itemIndex, quantityIndex)}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}

          <h2>Order Summary</h2>
          <table className="order-summary-table">
            <thead>
              <tr>
                <th>Product Code</th>
                <th>Size</th>
                <th>Quantity</th>
              </tr>
            </thead>
            <tbody>
              {orderDetails.items?.map((item, index) => (
                <tr key={index}>
                  <td>{item.code}</td>
                  <td>{item.size}</td>
                  <td>{item.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <button className="confirm-order-btn" type="submit">
            Confirm Order
          </button>

          {isModalOpen && (
            <div className="modal">
              <div className="modal-content">
                <h2>Are you sure you want to confirm the order?</h2>
                <button onClick={handleConfirm}>Yes</button>
                <button onClick={closeModal}>No</button>
              </div>
            </div>
          )}
        </form>
      ) : (
        <p>Loading order details...</p>
      )}
    </div>
  );
};

export default OrderPage;
