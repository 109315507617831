import React, { useState, useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/ProductPage.css';

const ProductPage = () => {
  const [cartItems, setCartItems] = useState(() => {
    // Retrieve initial cart items from local storage
    const storedItems = localStorage.getItem('cartItems');
    return storedItems ? JSON.parse(storedItems) : [];
  });

  // Save cart items to local storage whenever cartItems state changes
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const products = [
    {
      code: 'IS-01',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-01.jpeg?alt=media&token=c1d211b5-cf9a-42ee-9ac2-7bfd07511855',
      size: '6-4 inches',
      price: 150,
    },
    {
      code: 'IS-02',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-02.jpeg?alt=media&token=9a725790-6e6e-4af8-a8e9-af5a01b73d91',
      size: '8-6 inches',
      price: 200,
    },
    {
        code: 'IS-03',
        img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-03.jpeg?alt=media&token=eb4acea8-a524-4c26-82ea-4717c44f3b45',
        size: '10-8 inches',
        price: 300,
      },
    {
        code: 'IS-04',
        img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-04.jpeg?alt=media&token=91c697cd-6377-47c2-8a99-3c142b73d38f',
        size: '12-8 inches',
        price: 350,
    },
    {
        code: 'IS-05',
        img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-05.jpeg?alt=media&token=169bf270-eb0d-4cea-982f-6fd8ca297daf',
        size: '12-10 inches',
        price: 450,
    },
    {
        code: 'IS-06',
        img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-06.jpeg?alt=media&token=0db433ff-7e63-433f-b5d6-db6a68ef194c',
        size: '12-15 inches',
        price: 600,
    },
    {
        code: 'IS-07',
        img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-07.jpeg?alt=media&token=3b140c02-a7f0-4e03-9980-0e0c999c5322',
        size: '12-18 inches',
        price: 800,
    },
    {
        code: 'IS-08',
        img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-08.jpeg?alt=media&token=0ce0c626-577f-440b-a83d-3500c2005d3c',
        size: '24-12 inches',
        price: 1000,
    },
    {
        code: 'IS-09',
        img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-09.jpeg?alt=media&token=f2988383-213d-41f4-beb7-1c97414a39b8',
        size : '30-12 inches',
        price: 1300,
    },
    {
      code: 'IS-10',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-10.jpeg?alt=media&token=e8f193a1-49e5-435a-bc8a-1e3ecb4bc9b4',
      size : '36-12 inches',
      price: 1500,
    },
    {
      code: 'IS-11',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-11.jpeg?alt=media&token=e208b3a7-4f65-4bbf-b5b2-cdb7fef8c9f2',
      size:'20-16 inches',
      price:'1350',
    },
    {
      code: 'IS-12',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-12.jpeg?alt=media&token=04d4ba39-7693-4935-87fe-00f388b980d3',
      size:'24-20 inches',
      price:'1700',
    },
    {
      code: 'IS-13',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-13.jpeg?alt=media&token=c6463b8e-7fda-4f5c-987c-17c05b3bbc8a',
      size:'30-20 inches',
      price:'2320',
    },
    {
      code: 'IS-14',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-14.jpeg?alt=media&token=2f06744c-1d49-43a1-bb1d-b10285432b1b',
      size:'36-18 inches',
      price:'2800',
    },
    {
      code: 'IS-15',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-15.jpeg?alt=media&token=345174f3-6cf9-4af6-b3c3-1055c08aff4d',
      size:'36-20 inches',
      price:'2900',
    },
    {
      code: 'IS-16',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-16.jpeg?alt=media&token=eea67c54-c3c5-46bb-a6c3-89bc94de057c',
      size:'40-20 inches',
      price:'3200',
    },
    {
      code: 'IS-17',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-17.jpeg?alt=media&token=b9c45550-d753-4848-ab3a-794318ef8628',
      size:'36-24 inches',
      price:'3950',
    },
    {
      code: 'IS-18',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-18.jpeg?alt=media&token=24b1ce79-1c6d-4288-af69-961ab43337ca',
      size:'60-20 inches',
      price:'6000',
    },
    {
      code: 'IS-19',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-19.jpeg?alt=media&token=fada3fdc-89f5-40b6-86e6-e5a5b9f32f58',
      size:'72-24 inches',
      price:'7150',
    },

  ];

  const addToCart = (product) => {
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find(item => item.code === product.code);

      if (existingProduct) {
        return prevItems.map(item =>
          item.code === product.code
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });

    try {
      toast.success(`${product.size} added to cart!`);
    } catch (error) {
      console.error("Toast error:", error);
    }
  };

  return (
    <div className="product-gallery" id='1'>
      <h1>PHOTO PRODUCT</h1>
      <div className="product-container">
        {products.map((product, index) => (
          <div key={index} className="product-card">
            <img src={product.img_url} alt={product.code} className="product-image" />
            <h3 className='size'>{product.size}</h3>
            <p className='size'>₹{product.price}</p>
            <button className="add-to-cart-btn photo" onClick={() => addToCart(product)}>
              <FaShoppingCart /> Add to Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductPage;
