import React, { useState, useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/AcrylicPhotoProductPage.css';

const AcrylicPhotoProductPage = () => {
  const [cartItems, setCartItems] = useState(() => {
    // Retrieve initial cart items from local storage
    const storedItems = localStorage.getItem('cartItems');
    return storedItems ? JSON.parse(storedItems) : [];
  });

  // Save cart items to local storage whenever cartItems state changes
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const acrylicProducts = [
    {
      code: 'IS-44',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-44.jpg?alt=media&token=12313589-03d5-4f03-ad95-df12e244f87c',
      size: '10-8 inches',
      price: 499,
    },
    {
      code: 'IS-45',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-45.jpg?alt=media&token=ea3a0285-6211-467e-a8a6-8b13f8b9b63b',
      size: '12-8 inches',
      price: 649,
    },
    {
      code: 'IS-46',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-46.jpg?alt=media&token=2c47036d-ae0b-424a-9ecf-ab9129498fe5',
      size:'12-10 inches',
      price: 799,
    },
    {
      code: 'IS-47',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-47.jpg?alt=media&token=f231c5e1-61d4-4ed0-a33c-edc680f39805',
      size: '12-15 inches',
      price: 899,
    },
    {
      code: 'IS-48',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-48.jpg?alt=media&token=5d060a08-2a2a-4a00-93b6-75398b162f50',
      size: '12-18 inches',
      price: 1099,
    },

  ];

  const addToCart = (product) => {
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find(item => item.code === product.code);

      if (existingProduct) {
        return prevItems.map(item =>
          item.code === product.code
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });

    toast.success(`${product.size} added to cart!`, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  return (
    <div className="acrylic-product-gallery" id="6">
      <h1>ACRYLIC PHOTO PRINTS</h1>
      <div className="acrylic-product-container">
        {acrylicProducts.map((product, index) => (
          <div key={index} className="acrylic-product-card">
            <img src={product.img_url} alt={product.code} className="acrylic-products-image" />
            <h3>{product.size}</h3>
            <p>₹{product.price}</p>
            <button className="add-to-cart-btn app" onClick={() => addToCart(product)}>
              <FaShoppingCart /> Add to Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcrylicPhotoProductPage;
