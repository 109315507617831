import React from 'react';
import budgetframe from '../asset/budgetframe.png'; // Make sure this path is correct
import '../styles/BudgetFrame.css';

const BudgetFrame = () => {
  return (
    <div className="budget-frame-container">
      <div className="budget-image-container">
        <img src={budgetframe} alt="Budget Frame" className="budget-frame-image" />
      </div>
    </div>
  );
};

export default BudgetFrame;
