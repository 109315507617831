import React, { useState, useEffect, useRef } from 'react';
import { FaSearch, FaShoppingCart, FaUser, FaBars, FaTimes, FaFileAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import '../styles/Header.css';
import logo from '../asset/logo.JPG';
import { FaPhone } from "react-icons/fa6";
import { FaSignInAlt } from 'react-icons/fa';

const productSections = [
  { name: "Photo Frames", id: "1" },
  { name: "Acrylic Products", id: "2" },
  { name: "Caricature Cutouts", id: "3" },
  { name: "Wood Engraving Products", id: "4" },
  { name: "Neon Light Products", id: "5" },
  { name: "Acrylic Photo Prints", id: "6" },
];

const Header = () => {
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSections, setFilteredSections] = useState([]);
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (searchTerm) {
      const regex = new RegExp(searchTerm, 'i'); // Case-insensitive regex
      setFilteredSections(productSections.filter(section => regex.test(section.name)));
    } else {
      setFilteredSections([]);
    }
  }, [searchTerm]);

  const handleLogout = async () => {
    await auth.signOut();
    localStorage.clear();
    navigate('/login');
  };

  const handleSearchInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSectionScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setSearchTerm('');
    setFilteredSections([]);
  };

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="Logo" />
      </div>

      <div className="search-bar">
        <input 
          type="text" 
          placeholder="SEARCH FOR PRODUCTS" 
          value={searchTerm}
          onChange={handleSearchInputChange}
        />
        <button className="search-button">
          <FaSearch />
        </button>
        {filteredSections.length > 0 && (
          <ul className="search-results">
            {filteredSections.map((section) => (
              <li key={section.name} onClick={() => handleSectionScroll(section.id)}>
                {section.name}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="desktop-icons">
        <button className="icon-button" onClick={() => navigate('/cart')}>
          <FaShoppingCart />
        </button>
        <button className="icon-button" onClick={() => navigate('/orders')}>
          <FaFileAlt />
        </button>
        <button className="icon-button">
          <FaPhone />
        </button>

        {user ? (
          <button className="icon-button" onClick={() => setIsModalOpen(!isModalOpen)}>
            <FaUser />
          </button>
        ) : (
          <button className="login-button" onClick={() => navigate('/login')}>
            <FaSignInAlt /> Login
          </button>
        )}
      </div>

      {isModalOpen && (
        <div className="profile-modal" ref={modalRef}>
          <ul>
            <li onClick={() => navigate('/profile')}>View Profile</li>
            <li onClick={handleLogout}>Logout</li>
          </ul>
        </div>
      )}

      <button className="hamburger-icon" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        <FaBars />
      </button>

      {isSidebarOpen && (
        <div className="sidebar" ref={sidebarRef}>
          <button className="close-sidebar" onClick={() => setIsSidebarOpen(false)}>
            <FaTimes />
          </button>
          <ul>
            <li onClick={() => { navigate('/cart'); setIsSidebarOpen(false); }}>
              <FaShoppingCart /> Cart
            </li>
            <li onClick={() => { navigate('/orders'); setIsSidebarOpen(false); }}>
              <FaFileAlt /> View Orders
            </li>
            <li><FaPhone /> Contact Us</li>
            {user ? (
              <>
                <li onClick={() => { navigate('/profile'); setIsSidebarOpen(false); }}>View Profile</li>
                <li onClick={handleLogout}>Logout</li>
              </>
            ) : (
              <li>
                <button className="login-button" onClick={() => navigate('/login')}>
                  <FaSignInAlt /> Login
                </button>
              </li>
            )}
          </ul>
        </div>
      )}
    </header>
  );
};

export default Header;