import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaCheck, FaTimes, FaArrowLeft } from 'react-icons/fa'; // Import FaArrowLeft
import { auth, db } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/Profile.css';

const Profile = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook
  const [userData, setUserData] = useState(() => {
    const savedData = localStorage.getItem('userData');
    return savedData ? JSON.parse(savedData) : null;
  });
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    address: ''
  });

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserData(data);
          setFormData(data);
        } else {
          console.log("No such document!");
        }
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      localStorage.setItem('userData', JSON.stringify(userData));
    }
  }, [userData]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const docRef = doc(db, 'users', user.uid);
        await setDoc(docRef, formData, { merge: true });
        setUserData(formData);
        toast.success("Profile updated successfully!");
      }
    } catch (error) {
      toast.error("Error updating profile!");
    }
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setFormData(userData);
  };

  return (
    <div className="profile-page-container">
      <ToastContainer className="toastify-container" />

      {/* Back Arrow to go to the Home '/' */}
      <div className="back-arrow" onClick={() => navigate('/')}>
        <FaArrowLeft /> {/* Back arrow icon */}
      </div>

      {userData ? (
        <div>
          <div className="profile-heading">
            <h2>Profile Information</h2>
          </div>
          <div className="profile-field">
            <label className="profile-label">Username:</label>
            {isEditing ? (
              <input
                className="profile-input"
                type="text"
                value={formData.username}
                onChange={(e) => setFormData({ ...formData, username: e.target.value })}
              />
            ) : (
              <span className="profile-text">{userData.username}</span>
            )}
          </div>
          <div className="profile-field">
            <label className="profile-label">Email:</label>
            <span className="profile-text">{userData.email}</span>
          </div>
          <div className="profile-field">
            <label className="profile-label">Phone:</label>
            {isEditing ? (
              <input
                className="profile-input"
                type="tel"
                value={formData.phone}
                onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
              />
            ) : (
              <span className="profile-text">{userData.phone}</span>
            )}
          </div>
          <div className="profile-field">
            <label className="profile-label">Address:</label>
            {isEditing ? (
              <input
                className="profile-input"
                type="text"
                value={formData.address}
                onChange={(e) => setFormData({ ...formData, address: e.target.value })}
              />
            ) : (
              <span className="profile-text">{userData.address}</span>
            )}
          </div>
          <div className="profile-actions">
            {isEditing ? (
              <>
                <button className="profile-btn profile-save-btn" onClick={handleSave}>
                  <FaCheck /> Save
                </button>
                <button className="profile-btn profile-cancel-btn" onClick={handleCancel}>
                  <FaTimes /> Cancel
                </button>
              </>
            ) : (
              <button className="profile-btn profile-edit-btn" onClick={handleEdit}>
                <FaEdit /> Edit
              </button>
            )}
          </div>
        </div>
      ) : (
        <p className="profile-loading">Loading...</p>
      )}
    </div>
  );
};

export default Profile;
