import React, { useState, useEffect } from 'react';
import '../styles/HeroSection.css';
import heroImg1 from '../asset/hero1.png';
import heroImg2 from '../asset/hero2.png';
import heroImg3 from '../asset/hero3.png';
import eventImg2 from '../asset/small-dp-1.jpg';
import eventImg3 from '../asset/small-dp-5.jpg';
import eventImg4 from '../asset/small-dp-3.jpg';
import eventImg5 from '../asset/small-dp-4.jpg';
import eventImg6 from '../asset/small-dp-2.jpg';
import eventImg7 from '../asset/small-dp-6.jpg';
import bgvid from '../asset/background-vid2.mp4';

const HeroSection = () => {
  const images = [heroImg1, heroImg2, heroImg3];
  const [, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2500);
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="hero-section">
      <video autoPlay loop muted className="background-video">
        <source src={bgvid} type='video/mp4'/>
      </video>
      <div className="event-images animate">
        <img src={eventImg2} alt="Diwali Gifts" className="event-image animated-image1 delay-1"/>
        <img src={eventImg3} alt="Diwali Bulk Gifts" className="event-image animated-image1 delay-2"/>
        <img src={eventImg4} alt="Children's Day" className="event-image animated-image1 delay-3"/>
      </div>
      <div className="hero-content">
        <h1 className="hero-title">
          <span className="letter">E</span>
          <span className="letter">S</span>
          <span className="letter">W</span>
          <span className="letter">A</span>
          <span className="letter">R</span>
          <span className="letter">A</span>
          <span className="space"> </span>
          <span className="letter">G</span>
          <span className="letter">I</span>
          <span className="letter">F</span>
          <span className="letter">T</span>
          <span className="letter">S</span>
        </h1>
        <p className="hero-description">
          We Provide High Quality photo Printing Lab, Photo Frames, Caricature Cutout, 
          Wood Engraving, Neon Lights, Acrylic Photo Printing, AI Creations & Photo Restoration.
        </p>
      </div>
      <div className="event-images animate">
        <img src={eventImg5} alt="International Men's Day" className="event-image animated-image1 delay-1"/>
        <img src={eventImg6} alt="Christmas" className="event-image animated-image1 delay-2"/>
        <img src={eventImg7} alt="New Year" className="event-image animated-image1 delay-3"/>
      </div>
    </div>
  );
};

export default HeroSection;
