import React from 'react';
import '../styles/OurServicesPage.css'; // Import the CSS file

const OurServicesPage = () => {
  return (
    <div className="our-services-page">
      <h1>OUR SERVICES</h1>

      <div className="service-section">
        <div className="service-content">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FAI-creation.png?alt=media&token=adb36481-c071-416b-8704-1c132f439654"
            alt="Al Creations artwork"
            className="service-image"
          />
          <div className="text-content">
            <h2>Al CREATIONS</h2>
            <p>
              At Al Creations, we specialize in transforming your ideas into stunning visuals. 
              Our team of skilled designers works tirelessly to ensure that your vision comes to life, 
              whether it's through logo design, branding, or graphic design.
            </p>
          </div>
        </div>
      </div>

      <div className="service-section">
        <div className="service-content">
          <div className="text-content">
            <h2>PHOTO RESTORATION</h2>
            <p>
              Our photo restoration service revives your cherished memories. 
              We meticulously restore old, damaged, or faded photographs to their former glory, 
              ensuring that your priceless memories are preserved for generations to come.
            </p>
          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2Fphoto-restoration.png?alt=media&token=4488bf12-6f9d-4fb3-a4cf-add6a8bc2aaf"
            alt="Restored photograph"
            className="service-image"
          />
        </div>
      </div>
    </div>
  );
};

export default OurServicesPage;