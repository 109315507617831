import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { auth, db } from '../firebase'; // Import Firebase auth and Firestore db
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth"; // Firebase auth methods
import { toast, ToastContainer } from 'react-toastify'; // Import Toastify
import { doc, setDoc } from 'firebase/firestore'; // Firestore methods
import 'react-toastify/dist/ReactToastify.css'; // Import Toastify CSS
import '../styles/LoginSignupForm.css'; // Import the styles
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import eye icons

const LoginSignupForm = () => {
  const navigate = useNavigate(); // Initialize navigate
  const [isLoginActive, setIsLoginActive] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [showPassword, setShowPassword] = useState(false); // State for showing password
  const [, setUser] = useState(null); // State to hold the user

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      if (currentUser) {
        navigate('/'); // Navigate to home if user is logged in
      }
    });

    return () => unsubscribe(); // Clean up the subscription on unmount
  }, [navigate]);

  const handleToggle = () => {
    setIsLoginActive(!isLoginActive);
  };

  // Register user using Firebase
  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await setDoc(doc(db, "users", userCredential.user.uid), {
        username: username,
        email: email,
        phone: phone,
        address: address
      });

      // Success notification
      toast.success('Registration successful!', {
        position: "top-right"
      });
      
      setIsLoginActive(true); // Switch to login form after successful registration
    } catch (error) {
      // Error notification
      toast.error('Registration failed: ' + error.message, {
        position: "top-right"
      });
    }
  };

  // Login user using Firebase
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      toast.success('Login successful!', {
        position: "top-right"
      });
      // The navigation to home will be handled in useEffect
    } catch (error) {
      // Error notification
      toast.error('Login failed: ' + error.message, {
        position: "top-right"
      });
    }
  };

  return (
    <div className="login-signup-wrapper">
      <ToastContainer autoClose={3000} hideProgressBar={true} /> {/* Add ToastContainer for displaying notifications */}
      <div className={`login-signup-container ${isLoginActive ? '' : 'active'}`}>
        <div className="curved-shape"></div>
        <div className="curved-shape2"></div>

        {/* Login Form */}
        <div className="form-box Login">
          <h2 className="animation" style={{ '--D': 0, '--S': 21 }} id='reg'>Login</h2>
          <form onSubmit={handleLogin}>
            <div className="input-box animation" style={{ '--D': 1, '--S': 22 }}>
              <input type="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
              <label>Email</label>
              <box-icon name='envelope' type='solid'></box-icon>
            </div>

            <div className="input-box animation" style={{ '--D': 2, '--S': 23 }}>
              <input 
                type={showPassword ? "text" : "password"} 
                required 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
              <label>Password</label>
              <box-icon name='lock-alt' type='solid'></box-icon>
              <span className="password-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <div className="input-box animation" style={{ '--D': 3, '--S': 24 }}>
              <button className="btn" type="submit">Login</button>
            </div>

            <div className="regi-link animation" style={{ '--D': 4, '--S': 25 }}>
              <p className='to'>Don't have an account? <br />
                <button type="button" className="link-button" onClick={handleToggle}>Sign Up</button>
              </p>
            </div>
          </form>
        </div>

        {/* Information for Login */}
        <div className="info-content Login">
          <h2 className="animation" style={{ '--D': 0, '--S': 20 }}>WELCOME BACK!</h2>
          <p className="animation" style={{ '--D': 1, '--S': 21 }}>We are happy to have you with us again. If you need anything, we are here to help.</p>
        </div>

        {/* Register Form */}
        <div className="form-box Register">
          <h2 className="animation" style={{ '--li': 17, '--S': 0 }} id='reg'>Register</h2>
          <form onSubmit={handleRegister}>
            <div className="input-box animation" style={{ '--li': 18, '--S': 1 }}>
              <input type="text" required value={username} onChange={(e) => setUsername(e.target.value)} />
              <label>Username</label>
              <box-icon type='solid' name='user'></box-icon>
            </div>

            <div className="input-box animation" style={{ '--li': 19, '--S': 2 }}>
              <input type="email" required value={email} onChange={(e) => setEmail(e.target.value)} />
              <label>Email</label>
              <box-icon name='envelope' type='solid'></box-icon>
            </div>

            <div className="input-box animation" style={{ '--li': 20, '--S': 3 }}>
              <input type="tel" required value={phone} onChange={(e) => setPhone(e.target.value)} />
              <label>Phone Number</label>
              <box-icon name='phone' type='solid'></box-icon>
            </div>

            <div className="input-box animation" style={{ '--li': 21, '--S': 4 }}>
              <input type="text" required value={address} onChange={(e) => setAddress(e.target.value)} />
              <label>Address</label>
              <box-icon name='map' type='solid'></box-icon>
            </div>

            <div className="input-box animation" style={{ '--li': 22, '--S': 5 }}>
              <input 
                type={showPassword ? "text" : "password"} 
                required 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
              />
              <label>Password</label>
              <box-icon name='lock-alt' type='solid'></box-icon>
              <span className="password-icon" onClick={() => setShowPassword(!showPassword)}>
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
            </div>

            <div className="input-box animation" style={{ '--li': 23, '--S': 6 }}>
              <button className="btn" type="submit">Register</button>
            </div>

            <div className="regi-link animation" style={{ '--li': 24, '--S': 7 }}>
              <p className='to'>Already have an account? <br />
                <button type="button" className="link-button" onClick={handleToggle}>Sign In</button>
              </p>
            </div>
          </form>
        </div>

        {/* Information for Register */}
        <div className="info-content Register">
          <h2 className="animation" style={{ '--li': 23, '--S': 0 }}>WELCOME!</h2>
          <p className="animation" style={{ '--li': 24, '--S': 1 }}>We’re delighted to have you here. If you need any assistance, feel free to reach out.</p>
        </div>
      </div>
    </div>
  );
};

export default LoginSignupForm;