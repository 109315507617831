import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Header from './components/Header'; 
import HeroSection from './components/HeroSection';
import PhotoButtonPage from './components/PhotoButtonPage'; // Importing PhotoButtonPage
import LoginSignupForm from './components/LoginSignupForm'; 
import Profile from './components/Profile'; 
import ProductPage from './components/ProductPage'; 
import AcrylicProductPage from './components/AcrylicProductPage'; 
import CartPage from './components/CartPage';
import MyOrderListPage from './components/MyOrderListPage'; 
import OrderPage from './components/OrderPage'; 
import CaricatureProductPage from './components/CaricatureProductPage'; 
import WoodEngravingProductPage from './components/WoodEngravingProductPage'; 
import NeonLightProductPage from './components/NeonLightProductPage'; 
import AcrylicPhotoProductPage from './components/AcrylicPhotoProductPage';
import AcrylicPage from './components/AcrylicPage';
import BudgetFrame from './components/BudgetFrame';
import OurServicesPage from './components/OurServicesPage';
import Footer from './components/Footer';
import NotFoundPage from './components/404Page'; // Importing 404Page

const App = () => {
  const location = useLocation();

  return (
    <div>
      <ToastContainer position="top-right" autoClose={2000} />

      {location.pathname !== '/login' && location.pathname !== '/profile' && location.pathname !== '/cart' && location.pathname !== '/orders' && !location.pathname.startsWith('/orders/') && (
        <>
          <Header />
          <HeroSection />
          <PhotoButtonPage /> 
        </>
      )}

      <Routes>
        <Route path="/login" element={<LoginSignupForm />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/cart" element={<CartPage />} /> 
        <Route path="/orders" element={<MyOrderListPage />} /> 
        <Route path="/orders/:orderId" element={<OrderPage />} /> 
        
        <Route
          path="/"
          element={
            <div>
              <ProductPage /> 
              <AcrylicProductPage /> 
              <CaricatureProductPage />
              <WoodEngravingProductPage />
              <NeonLightProductPage />
              <AcrylicPhotoProductPage />
              <AcrylicPage />
              <BudgetFrame />
              <OurServicesPage />
              <Footer />
            </div>
          }
        />
        
        {/* Catch-all Route for 404 Page */}
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

const AppWithRouter = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
