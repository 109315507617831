import React, { useState, useEffect } from 'react';
import { FaTrashAlt, FaHome } from 'react-icons/fa'; // Importing Home icon
import { ImPriceTags } from "react-icons/im";
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase'; // Firebase imports
import { doc, setDoc } from 'firebase/firestore'; // Firestore methods
import { v4 as uuidv4 } from 'uuid'; // For generating unique IDs for orders
import '../styles/CartPage.css';

const CartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [user, setUser] = useState(null); // Track the logged-in user
  const navigate = useNavigate();

  useEffect(() => {
    const storedItems = localStorage.getItem('cartItems');
    if (storedItems) {
      setCartItems(JSON.parse(storedItems));
    }

    // Check if user is logged in
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const removeFromCart = (productCode) => {
    const updatedCart = cartItems.filter(item => item.code !== productCode);
    setCartItems(updatedCart);
    localStorage.setItem('cartItems', JSON.stringify(updatedCart)); // Update local storage
  };

  const updateQuantity = (productCode, quantity) => {
    const updatedCart = cartItems.map(item =>
      item.code === productCode ? { ...item, quantity: Math.max(1, quantity) } : item
    );
    setCartItems(updatedCart);
    localStorage.setItem('cartItems', JSON.stringify(updatedCart)); // Update local storage
  };

  const calculateTotal = () => {
    return cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  // Handle placing the order
  const handlePlaceOrder = async () => {
    if (!user) {
      navigate('/login'); // Redirect to login if not logged in
      return;
    }

    // Create a new order in Firestore with Confirm_order: False
    const orderId = uuidv4(); // Generate a unique ID for the order
    const orderData = {
      user_id: user.uid,
      items: cartItems,
      total: calculateTotal(),
      Confirm_order: false,
      created_at: new Date(),
    };

    try {
      await setDoc(doc(db, "orders", orderId), orderData);
      // Clear the cart after placing the order
      setCartItems([]);
      localStorage.removeItem('cartItems');

      // Navigate to orders page
      navigate('/orders');
    } catch (error) {
      console.error("Error placing order:", error);
    }
  };

  return (
    <div className="cart-container">
      <h1>
        <FaHome onClick={() => navigate('/')} style={{ cursor: 'pointer', marginRight: '10px' }} />
        Your Cart
      </h1>
      {cartItems.length === 0 ? (
        <>
         <img src="https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2Fno_item_cart.png?alt=media&token=e78b6064-996c-45d7-b927-d85c24e6a235" alt="Empty Cart" className="empty-cart-image" /> 
        </>
      ) : (
        <>
          <table className="cart-table">
            <thead>
              <tr>
                <th>Product</th>
                <th>Code</th>
                <th>Size</th>
                <th>Price</th>
                <th>Quantity</th>
                <th>Subtotal</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {cartItems.map((item, index) => (
                <tr key={index}>
                  <td><img src={item.img_url} alt={item.code} className="cart-product-image" /></td>
                  <td>{item.code}</td>
                  <td>{item.size}</td>
                  <td>₹{item.price}</td>
                  <td>
                    <div className="quantity-controls">
                      <button onClick={() => updateQuantity(item.code, item.quantity - 1)}>-</button>
                      <span>{item.quantity}</span>
                      <button onClick={() => updateQuantity(item.code, item.quantity + 1)}>+</button>
                    </div>
                  </td>
                  <td>₹{item.price * item.quantity}</td>
                  <td>
                    <button className="delete-btn" onClick={() => removeFromCart(item.code)}>
                      <FaTrashAlt />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="cart-summary">
            <span>Grand Total: ₹{calculateTotal()}</span>
            <button className="place-order-btn" onClick={handlePlaceOrder}>
              <ImPriceTags /> Place Order
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default CartPage;
