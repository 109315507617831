import React, { useState, useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/AcrylicPage.css';

const AcrylicPage = () => {
  const [cartItems, setCartItems] = useState(() => {
    const storedItems = localStorage.getItem('cartItems');
    return storedItems ? JSON.parse(storedItems) : [];
  });

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const acrylicProducts = [
    {
      code: 'IS-49',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-49.jpg?alt=media&token=a95660ae-2db5-4ea7-a16b-e8bcba7c1fdb',
      size: 'Acrylic Key Chain',
      price: 200,
    },
    {
      code: 'IS-50',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-50.jpg?alt=media&token=4422f8f0-932f-4805-bbda-db3df6caeb04',
      size: 'Acrylic Engraving',
      price: 399,
    },
    {
      code: 'IS-51',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-51.jpg?alt=media&token=8c4a0e63-4720-4d22-8c03-dd5fa4d664f7',
      size: 'Moon Lamp',
      price:800,
    },
    {
      code:'IS-52',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-52.jpg?alt=media&token=a17e5e4e-d0e9-4a59-a1a5-85c7a69d5cdf',
      size: 'Tiles Printing',
      price:485,
    },
    {
      code:'IS-53',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-53.jpg?alt=media&token=f3d1d4cc-9af1-4bd9-9a9a-274ddecc9325',
      size: 'Magic Mirror',
      price: 350,
    },
    {
      code:'IS-54',
      img_url:'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-54.jpg?alt=media&token=319abd6a-975a-493b-9a18-7c2e09dc080d',
      size: 'Wallet',
      price: 500,
    }

  ];

  const addToCart = (product) => {
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find(item => item.code === product.code);
      if (existingProduct) {
        return prevItems.map(item =>
          item.code === product.code
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });

    toast.success(`${product.size} added to cart!`, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  return (
    <div className="acrylic-page" id="7">
      <h1>ACRYLIC PRODUCT</h1>
      <div className="acrylic-product-grid">
        {acrylicProducts.map((product) => (
          <div key={product.code} className="acrylic-card">
            <img
              src={product.img_url}
              alt={product.code}
              className="acrylic-product-images"
            />
            <h3>{product.size}</h3>
            <p>₹{product.price}</p>
            <button
              className="acrylic-add-to-cart-btn"
              onClick={() => addToCart(product)}
            >
              <FaShoppingCart /> Add to Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcrylicPage;
