import React, { useState, useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/WoodEngravingProductPage.css';

const WoodEngravingProductPage = () => {
  const [cartItems, setCartItems] = useState(() => {
    const storedItems = localStorage.getItem('cartItems');
    return storedItems ? JSON.parse(storedItems) : [];
  });

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const woodEngravingProducts = [
    {
      code: 'IS-37',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-40.jpeg?alt=media&token=352ef5d6-eb65-4df4-823a-e8f7ee43d7d7',
      size: '6x4 inches Square',
      price: 499,
    },
    {
      code: 'IS-38',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-38.jpeg?alt=media&token=bb10b681-a461-4153-88bb-e09a480c760f',
      size: '8x6 inches Square',
      price: 699,
    },
    {
      code: 'IS-39',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-39.jpeg?alt=media&token=22c4b923-6f4c-4543-95cd-04da16bf4981',
      size: '12x8 inches Square',
      price: 999,
    },
    {
      code: 'IS-40',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-37.jpg?alt=media&token=630e74f8-2f31-42d2-985b-e46375205f8c',
      size: '12x18 inches Square',
      price: 1449,
    },
    {
      code: 'IS-41',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-41.jpeg?alt=media&token=f90d6fd5-c126-43fd-9998-9f710abcab2e',
      size: '8x7 inches Heart',
      price: '749',
    },
    {
      code: 'IS-42',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-42.jpeg?alt=media&token=f40d5022-41cb-4dba-a375-d6114a1a87b2',
      size: '12x10 inches Heart',
      price: '1099',
    },

  ];

  const addToCart = (product) => {
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find(item => item.code === product.code);

      if (existingProduct) {
        return prevItems.map(item =>
          item.code === product.code
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });

    toast.success(`${product.size} added to cart!`, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  return (
    <div className="wood-engraving-product-gallery" id="4">
      <h1>Wood Engraving Products</h1>
      <div className="wood-engraving-product-container">
        {woodEngravingProducts.map((product, index) => (
          <div key={index} className="wood-engraving-product-card">
            <div className="image-container">
              <img src={product.img_url} alt={product.code} className="wood-engraving-product-image" />
            </div>
            <h3>{product.size}</h3>
            <p>₹{product.price}</p>
            <button className="add-to-cart-btn wood" onClick={() => addToCart(product)}>
              <FaShoppingCart /> Add to Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WoodEngravingProductPage;
