import React from 'react';
import { FaFacebookF, FaInstagram, FaWhatsapp } from 'react-icons/fa';
import '../styles/Footer.css';
import { FaYoutube } from "react-icons/fa6";

const Footer = () => {
  // Scroll to the section with smooth behavior
  const handleSectionScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        {/* Logo Section */}
        <div className="footer-left">
          <img src={require('../asset/logo.JPG')} alt="Logo" className="footer-logo" />
        </div>
        

        {/* Quick Links Section */}
        <div className="footer-center">
          <h4>Quick Links</h4>
          <ul>
            <li><span onClick={() => handleSectionScroll('1')}>ProductPage</span></li>
            <li><span onClick={() => handleSectionScroll('2')}>AcrylicProductPage</span></li>
            <li><span onClick={() => handleSectionScroll('3')}>CaricatureProductPage</span></li>
            <li><span onClick={() => handleSectionScroll('4')}>WoodEngravingProductPage</span></li>
            <li><span onClick={() => handleSectionScroll('5')}>NeonLightProductPage</span></li>
            <li><span onClick={() => handleSectionScroll('6')}>AcrylicPhotoProductPage</span></li>
            <li><span onClick={() => handleSectionScroll('7')}>OurServicesPage</span></li>
          </ul>
        </div>

        {/* Contact Section */}
        <div className="footer-right">
          <h4>Contact Us</h4>
          <p>
            <a href="tel:8608062168">8608062168</a> || <a href="tel:9715776288">9715776288</a>
          </p>
          <p><a href="mailto:eswaragifts@gmail.com">eswaragifts@gmail.com</a></p>
          <div className="icon-container">
            <a href="https://wa.me/9715776288" target="_blank" rel="noopener noreferrer" className="icon whatsapp-icon">
              <FaWhatsapp />
            </a>
            <a href="https://www.instagram.com/eswara_gifts?igsh=MXY0bG92MDV5dmxqdQ==" target="_blank" rel="noopener noreferrer" className="icon instagram-icon">
              <FaInstagram />
            </a>
            <a href="https://www.facebook.com/61557420357038/posts/pfbid0s3P7Ut7QCB2hnY6zJonmZyGAXGgxZGz6pw2mZYabkU6HFHhNar5h8xuRNxav8yHAl/?app=fbl" target="_blank" rel="noopener noreferrer" className="icon facebook-icon">
              <FaFacebookF />
            </a>
            <a href="https://www.youtube.com/watch?v=Uy11M0jkA4Y" target="_blank" rel="noopener noreferrer" className="icon linkedin-icon">
              <FaYoutube />
            </a>
          </div>
        </div>

      </div>

      <hr />

      {/* Social Media Section */}
      <div className="footer-bottom">

        <p>© Copyright by DDDIGITAL CONSTRUCTION. 2024</p>
      </div>
    </footer>
  );
};

export default Footer;
