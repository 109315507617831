import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; 
import { getStorage } from "firebase/storage"; // Import Storage

const firebaseConfig = {
    apiKey: "AIzaSyB5EdWfkwrxQMiAgbkakzxKftD9xxj2SK0",
    authDomain: "infinitesurpires.firebaseapp.com",
    projectId: "infinitesurpires",
    storageBucket: "infinitesurpires.appspot.com",
    messagingSenderId: "54594841346",
    appId: "1:54594841346:web:c0e1b30da5573da047301b",
    measurementId: "G-J7QW87PP5H"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app); 
export const storage = getStorage(app); // Initialize Storage and export it
