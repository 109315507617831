import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaExclamationTriangle } from 'react-icons/fa'; // React icons for home and warning
import '../styles/404Page.css';

const NotFoundPage = () => {
  return (
    <div className="notfound-container">
      <div className="notfound-content">
        <FaExclamationTriangle className="notfound-icon" />
        <h1 className="notfound-title">Oops! Page Not Found</h1>
        <p className="notfound-message">
          It seems like you've lost your way. Let's get you back on track.
        </p>
        <Link to="/" className="back-home-button">
          <FaHome className="home-icon" />
          Return to Home
        </Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
