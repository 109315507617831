import React, { useState, useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/CaricatureProductPage.css';

const CaricatureProductPage = () => {
  const [cartItems, setCartItems] = useState(() => {
    // Retrieve initial cart items from local storage
    const storedItems = localStorage.getItem('cartItems');
    return storedItems ? JSON.parse(storedItems) : [];
  });

  // Save cart items to local storage whenever cartItems state changes
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const caricatureProducts = [
    {
      code: 'IS-34',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-34.jpg?alt=media&token=b9b393f3-ec12-4f2f-aeb3-e690eb0db667',
      size: '6-4 inches',
      price: 350,
    },
    {
      code: 'IS-35',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-35.jpg?alt=media&token=499cb845-45de-4b67-b93d-058c1cbe17c4',
      size: '8-6 inches',
      price: 450,
    },
    {
      code: 'IS-36',
      img_url: 'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-36.jpg?alt=media&token=47bb8a83-7e55-4e02-85e6-c4ffe252c091',
      size: '12-8 inches',
      price: 549,
    },
  ];

  const addToCart = (product) => {
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find(item => item.code === product.code);

      if (existingProduct) {
        return prevItems.map(item =>
          item.code === product.code
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...product, quantity: 1 }];
      }
    });

    toast.success(`${product.size} added to cart!`, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  return (
    <div className="caricature-product-gallery" id="3">
      <h1>CARICATURE CUTOUTS</h1>
      <div className="caricature-product-container">
        {caricatureProducts.map((product, index) => (
          <div key={index} className="caricature-product-card">
            <img src={product.img_url} alt={product.code} className="caricature-product-image" />
            <h3 className="size">{product.size}</h3>
            <p className="size">₹{product.price}</p>
            <button className="add-to-cart-btn cpp" onClick={() => addToCart(product)}>
              <FaShoppingCart /> Add to Cart
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaricatureProductPage;
