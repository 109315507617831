import React, { useState, useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../styles/NeonLightProductPage.css';

const NeonLightProductPage = () => {
  const [cartItems, setCartItems] = useState(() => {
    const storedItems = localStorage.getItem('cartItems');
    return storedItems ? JSON.parse(storedItems) : [];
  });

  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  const neonLightProduct = {
    code: 'IS-43',
    images: [
      'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-43%20(A).jpg?alt=media&token=1a1bf058-e835-4a89-b97d-1ace822ab52c',
      'https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2FIS-43.jpg?alt=media&token=ad7b4222-f497-4c0b-b11a-211a5da69fbd',
    ],
    product_name: 'Neon Lights per/Sq.ft',
    price: 1399,
  };

  const addToCart = (product) => {
    const productWithFirstImage = { ...product, img_url: product.images[0] };
    setCartItems((prevItems) => {
      const existingProduct = prevItems.find(item => item.code === product.code);

      if (existingProduct) {
        return prevItems.map(item =>
          item.code === product.code
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      } else {
        return [...prevItems, { ...productWithFirstImage, quantity: 1 }];
      }
    });

    toast.success(`${product.product_name} added to cart!`, {
      position: "top-right",
      autoClose: 2000,
    });
  };

  return (
    <div className="neon-light-product-gallery" id="5">
      <h1>NEON LIGHTS PRODUCT</h1>
      <div className="animate-container22">
        <video
          src="https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2Fgif1.mp4?alt=media&token=1b485817-04bb-42e4-b688-b350fae30cf9"
          className="neon-light-video"
          autoPlay
          loop
          muted
        ></video>
        <div className="neon-light-product-container">
          <div className="neon-light-product-card">
            <div className="neon-light-product-images">
              {neonLightProduct.images.map((img_url, index) => (
                <img key={index} src={img_url} alt={`${neonLightProduct.code}-${index}`} className="neon-light-product-image" />
              ))}
            </div>
            <h3>{neonLightProduct.product_name}</h3>
            <p>₹{neonLightProduct.price}</p>
            <button className="add-to-cart-btn neon" onClick={() => addToCart(neonLightProduct)}>
              <FaShoppingCart /> Add to Cart
            </button>
          </div>
        </div>
        <video
          src="https://firebasestorage.googleapis.com/v0/b/infinitesurpires.appspot.com/o/assets%2Fgif2.mp4?alt=media&token=4ac013de-c32a-401f-8f32-168b90207891"
          className="neon-light-video"
          autoPlay
          loop
          muted
        ></video>
      </div>
    </div>
  );
};

export default NeonLightProductPage;
